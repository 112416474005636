import React, { useEffect, useState, useContext } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import OrderETA from "./OrderETA";
import StoreDetails from "./StoreDetails";
import StoreHours from "./StoreHours";
import StoreClosures from "./StoreClosures";
import { APPLICATION, BASE_API } from "../../../constants";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import StoreStatus from "./StoreStatus";
import StoreMenuSettings from "./StoreMenuSettings";
import StoreContext from "../../../StoreContext";
import StoreRewards from "./StoreRewards";

function StoreSettings(props) {
  const storeContext = useContext(StoreContext);
  const [storeClosures, setStoreClosures] = useState();
  const [menu, setMenu] = useState();
  const [settingsKey, setSettingsKey] = useState("OrderEstimates");

  useEffect(() => {
    if (APPLICATION) {
      if (settingsKey === "StoreMenu") {
        fetchMenuData();
      } else if (settingsKey === "StoreClosures") {
        getStoreClosures();
      }
    }
  }, [settingsKey]);

  async function getStoreClosures() {
    try {
      const response = await axios.get(
        `${BASE_API}api/Hours/GetStoreClosures`,
        {
          headers: {
            Application: APPLICATION,
          },
        }
      );

      const formattedAndSortedData = response.data
        .map((item) => ({
          dateClosed: new Date(item.dateClosed).toLocaleDateString("en-US", {
            year: "numeric",
            month: "long",
            day: "numeric",
          }),
          storeID: item.storeID
        }))
        .sort((a, b) => new Date(a.dateClosed) - new Date(b.dateClosed));

      setStoreClosures(formattedAndSortedData);
    } catch (err) {
      toast.error("Failed to get store closures.");
    }
  }

  const fetchMenuData = async () => {
    try {
      const response = await axios.get(`${BASE_API}api/Menu/AllMenuItems`, {
        headers: {
          Application: APPLICATION,
        },
      });

      // Create an object to store items by categoryName
      const itemsByCategory = {};
      // Loop through the dataArray

      response.data.forEach((item) => {
        const categoryName = item.itemCategory;
        // If the category doesn't exist in the object, create an array for it
        if (!itemsByCategory[categoryName]) {
          itemsByCategory[categoryName] = [];
        }
        // Add the current item to the array for its category
        itemsByCategory[categoryName].push(item);
      });

      setMenu(itemsByCategory);
    } catch (error) {
      console.log(error);
    }
  };

  async function setKey(key) {
    setSettingsKey(key);

    var alreadyActiveElement = document.querySelector(
      `.AdminSettingsTabs .nav-item .nav-link.active`
    );
    if (alreadyActiveElement) {
      alreadyActiveElement.style.backgroundColor = "hsla(0, 0%, 84%, 0.85)";
      alreadyActiveElement.style.color = `black`;
      alreadyActiveElement.style.border = `none`;
      alreadyActiveElement.style.borderBottom = `1px solid black`;
    }

    const alphaValue = 0.5;
    // Parse the original color string to extract the RGB values
    const match = storeContext.color.match(/\d+/g);
    let modifiedLightColor;
    if (match && match.length === 3) {
      const [red, green, blue] = match;

      // Create the new color string with the alpha value
      modifiedLightColor = `rgba(${red}, ${green}, ${blue}, ${alphaValue})`;
    }

    var activeThisElement = document.getElementById(
      `controlled-tab-example-tab-${key}`
    );
    if (activeThisElement) {
      activeThisElement.style.backgroundColor = modifiedLightColor;
      activeThisElement.style.color = `white`;
      activeThisElement.style.border = `2px solid ${storeContext.color}`;
      activeThisElement.style.borderBottom = `2px solid ${storeContext.color}`;
    }
  }

  useEffect(() => {
    var activeThisElement = document.getElementById(
      `controlled-tab-example-tab-${settingsKey}`
    );
    const alphaValue = 0.5;
    // Parse the original color string to extract the RGB values
    const match = storeContext.color.match(/\d+/g);
    let modifiedLightColor;
    if (match && match.length === 3) {
      const [red, green, blue] = match;

      // Create the new color string with the alpha value
      modifiedLightColor = `rgba(${red}, ${green}, ${blue}, ${alphaValue})`;
    }
    if (activeThisElement) {
      activeThisElement.style.backgroundColor = modifiedLightColor;
      activeThisElement.style.color = `white`;
      activeThisElement.style.border = `2px solid ${storeContext.color}`;
      activeThisElement.style.borderBottom = `2px solid ${storeContext.color}`;
    }
  }, []);

  return (
    <div className="store_settings">
      <Tabs
        id="controlled-tab-example"
        className="mb-3 AdminSettingsTabs"
        activeKey={settingsKey}
        onSelect={(k) => setKey(k)}
      >
        <Tab eventKey="OrderEstimates" title="Estimates">
          <OrderETA />
        </Tab>
        <Tab eventKey="StoreDetails" title="Details">
          <StoreDetails />
        </Tab>
        <Tab eventKey="StoreHours" title="Hours">
          <StoreHours />
        </Tab>
        <Tab eventKey="StoreMenu" title="Menu">
          <StoreMenuSettings menu={menu} setMenu={setMenu} />
        </Tab>
        <Tab eventKey="StoreRewards" title="Rewards">
          <StoreRewards setStoreContext={props.setStoreContext} menuContext={props.menuContext} />
        </Tab>
        <Tab eventKey="StoreClosures" title="Closed Days">
          <StoreClosures
            getStoreClosures={getStoreClosures}
            storeClosures={storeClosures}
            setStoreClosures={setStoreClosures}
          />
        </Tab>
        <Tab eventKey="StoreStatus" title="Status">
          <StoreStatus setStoreContext={props.setStoreContext} />
        </Tab>
      </Tabs>
    </div>
  );
}

export default StoreSettings;
