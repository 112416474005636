import React, { useContext, useState } from "react";
import ApplicationContext from "../../../ApplicationContext";
import StoreContext from "../../../StoreContext";
import axios from "axios";
import { APPLICATION, BASE_API } from "../../../constants";
import { toast } from "react-toastify";

function StoreDetails() {
  const appContext = useContext(ApplicationContext);
  const storeContext = useContext(StoreContext);

  const [name, setName] = useState(storeContext.name);
  const [address, setAddress] = useState(storeContext.address);
  const [phoneNumber, setPhoneNumber] = useState(storeContext.phoneNumber);

  async function changeStoreDetails() {
    if(APPLICATION){
      var store = {
        Name: name,
        Address: address,
        PhoneNumber: phoneNumber
      }
      try{
        await axios.put(
          `${BASE_API}api/Store/UpdateStoreDetails`,
          store,
          {
            headers: {
              Authorization: `Bearer ${appContext.userInfo.token}`,
              Application: APPLICATION,
            },
          }
        );
        toast.success("Updated Store Details Successfully")
      } catch (err) {
        toast.error("Failed to update store details");
      }
    }
  }

  return (
    <div className="store_details card">
      <div className="card-body">
        <div className="input_fields">
          <label>Name:</label>
          <input
            type="text"
            placeholder={storeContext.name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="input_fields">
          <label>Address:</label>
          <input
            type="text"
            placeholder={storeContext.address}
            onChange={(e) => setAddress(e.target.value)}
          />
        </div>
        <div className="input_fields">
          <label>Phone Number:</label>
          <input
            type="text"
            placeholder={storeContext.phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
          />
        </div>
        <div className="submit">
          <button onClick={changeStoreDetails}>Submit</button>
        </div>
      </div>
    </div>
  );
}

export default StoreDetails;
