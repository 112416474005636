import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { FaWindowClose } from "react-icons/fa";
import ApplicationContext from "../../../ApplicationContext";
import StoreContext from "../../../StoreContext";
import { APPLICATION, BASE_API, TAX_PERCENTAGE } from "../../../constants";
import { toast } from "react-toastify";
import Loading from "../../../tools/Loading";
import { getNowTime } from "../../../utils/time/GetNowTime";

function AreYouSure(props) {
  const appContext = useContext(ApplicationContext);
  const storeContext = useContext(StoreContext);

  const [isDemo, setIsDemo] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const pathname = window.location.pathname;
    const store = pathname.split("/")[1];
    if (store === "demostore" || store === "demostore2") {
      if (storeContext.mxMerchant !== "true") {
        setIsDemo("AuthorizeNetDemo");
      } else {
        setIsDemo("MxMerchantDemo");
      }
    } else {
      if (storeContext.mxMerchant !== "true") {
        setIsDemo("AuthorizeNet");
      } else {
        setIsDemo("MxMerchant");
      }
    }
  }, []);

  async function deny() {
    props.setShowAreYouSure(false);
  }

  // #region COMPLETE ORDER
  async function confirmComplete() {
    if (isLoading) {
      return; // Prevent double-clicks while loading
    }
    setIsLoading(true);
    await completeOrder();
    setIsLoading(false);
  }

  async function completeOrder() {
    var utcISOString = getNowTime();
    var completeData;
    var orderData = props.orderData;
    var totalToCapture;
    if (orderData.discountedChargePercent !== null) {
      totalToCapture = orderData.discountedTotal;
    } else {
      totalToCapture = orderData.orderTotal;
    }
    if (props.orderData.payLater === "true") {
      completeData = {
        orderId: orderData.orderID,
        completedDate: utcISOString,
      };
      await axios.put(`${BASE_API}CompleteOrder`, completeData, {
        headers: {
          Authorization: `Bearer ${appContext.userInfo.token}`,
          Application: APPLICATION,
        },
      });

      props.getOpenOrders();
      props.setShowAreYouSure(false);
      toast.success("Order Completed Successfully");
    } else {
      if (storeContext.mxMerchant === "true") {
        var mxCaptureData = {
          paymentToken: orderData.paymentToken,
          tenderType: "Card",
          amount: totalToCapture,
          authCode: orderData.authCode,
        };

        var mxResult = await axios.post(
          `${BASE_API}api/${isDemo}/capture-mxmerchant-payment`,
          mxCaptureData,
          {
            headers: {
              Authorization: `Bearer ${appContext.userInfo.token}`,
              "Content-Type": "application/json",
              Application: APPLICATION,
            },
          }
        );

        var status = JSON.parse(mxResult.data.content);
        status = status.status;
        if (status === "Approved") {
          completeData = {
            orderId: orderData.orderID,
            completedDate: utcISOString,
          };
          await axios.put(`${BASE_API}CompleteOrder`, completeData, {
            headers: {
              Authorization: `Bearer ${appContext.userInfo.token}`,
              Application: APPLICATION,
            },
          });

          props.getOpenOrders();
          props.setShowAreYouSure(false);
          toast.success("Order Completed Successfully");
        } else {
          console.log(" failed");
        }
      } else if (storeContext.mxMerchant !== "true") {
        var authNetCaptureData = {
          TransactionAmount: totalToCapture,
          TransactionID: orderData.transactionID,
        };

        var resultAuthNetCapture = await axios.post(
          `${BASE_API}api/${isDemo}/capture-authorizenet-payment`,
          authNetCaptureData,
          {
            headers: {
              Authorization: `Bearer ${appContext.userInfo.token}`,
              "Content-Type": "application/json",
              Application: APPLICATION,
            },
          }
        );
        for (
          var a = 0;
          a < resultAuthNetCapture.data.transactionResponse.messages.length;
          a++
        ) {
          if (
            resultAuthNetCapture.data.transactionResponse.messages[a].code ===
            "1"
          ) {
            completeData = {
              orderId: orderData.orderID,
              completedDate: utcISOString,
            };
            await axios.put(`${BASE_API}CompleteOrder`, completeData, {
              headers: {
                Authorization: `Bearer ${appContext.userInfo.token}`,
                Application: APPLICATION,
              },
            });

            props.getOpenOrders();
            props.setShowAreYouSure(false);
            toast.success("Order Completed Successfully");
            return;
          }
        }
        toast.error(
          resultAuthNetCapture.data.transactionResponse.messages[0].description
        );
      }
    }
  }
  // #endregion
  //

  // #region CANCEL ORDER
  async function confirmCancel() {
    if (isLoading) {
      return; // Prevent double-clicks while loading
    }
    setIsLoading(true);
    await cancelOrder();
    setIsLoading(false);
  }
  async function cancelOrder() {
    var utcISOString = getNowTime();

    if (props.orderData.payLater === "true") {
      props.orderData.cancelledDate = utcISOString;
      await axios.post(`${BASE_API}CancelOrder`, props.orderData, {
        headers: {
          Authorization: `Bearer ${appContext.userInfo.token}`,
          "Content-Type": "application/json",
          Application: APPLICATION,
        },
      });

      props.getOpenOrders();
      props.setShowAreYouSure(false);
      toast.success("Order Cancelled");
    } else {
      if (storeContext.mxMerchant === "true") {
        await axios.post(
          `${BASE_API}api/${isDemo}/void-mxmerchant-payment/${props.orderData.paymentID}`,
          props.orderData.paymentID,
          {
            headers: {
              Authorization: `Bearer ${appContext.userInfo.token}`,
              "Content-Type": "application/json",
              Application: APPLICATION,
            },
          }
        );

        props.orderData.cancelledDate = utcISOString;
        await axios.post(`${BASE_API}CancelOrder`, props.orderData, {
          headers: {
            Authorization: `Bearer ${appContext.userInfo.token}`,
            "Content-Type": "application/json",
            Application: APPLICATION,
          },
        });

        props.getOpenOrders();
        props.setShowAreYouSure(false);
        toast.success("Order Cancelled");
      } else if (storeContext.mxMerchant !== "true") {
        var orderData = props.orderData;
        props.orderData.cancelledDate = utcISOString;
        await axios.post(`${BASE_API}CancelOrder`, props.orderData, {
          headers: {
            Authorization: `Bearer ${appContext.userInfo.token}`,
            "Content-Type": "application/json",
            Application: APPLICATION,
          },
        });
        var transactionId = orderData.transactionID;
        await axios.post(
          `${BASE_API}api/${isDemo}/VoidTransaction/${transactionId}`,
          { transactionId },
          {
            headers: {
              Authorization: `Bearer ${appContext.userInfo.token}`,
              "Content-Type": "application/json",
              Application: APPLICATION,
            },
          }
        );
        props.getOpenOrders();
        props.setShowAreYouSure(false);
        toast.success("Order Cancelled");
      }
    }
  }
  // #endregion
  //

  // #region ADD EXTRA CHARGE(s)
  async function confirmExtraCharge() {
    setIsLoading(true);
    var newOrderTotal = 0;
    var differenceToCharge;
    if (storeContext.mxMerchant === "true") {
      try {
        differenceToCharge =
          props.chargeOrderAmount + props.chargeOrderAmount * TAX_PERCENTAGE;
        differenceToCharge = parseFloat(differenceToCharge.toFixed(2));
        if (props.orderData.discountedTotal) {
          newOrderTotal = props.orderData.discountedTotal + differenceToCharge;
          newOrderTotal = parseFloat(newOrderTotal.toFixed(2));
        } else {
          newOrderTotal = props.orderData.orderTotal + differenceToCharge;
          newOrderTotal = parseFloat(newOrderTotal.toFixed(2));
        }
        await updateChargedOrder(newOrderTotal, props.orderData.orderID);
        await addMxMerchantExtraCharge(differenceToCharge);
        await completeOrderAfterCharge();
      } catch (err) {
        toast.error("Failed to add extra charge.");
      }
    } else if (storeContext.mxMerchant !== "true") {
      try {
        differenceToCharge =
          props.chargeOrderAmount + props.chargeOrderAmount * TAX_PERCENTAGE;
        differenceToCharge = parseFloat(differenceToCharge.toFixed(2));
        if (props.orderData.discountedTotal) {
          newOrderTotal = props.orderData.discountedTotal + differenceToCharge;
          newOrderTotal = parseFloat(newOrderTotal.toFixed(2));
        } else {
          newOrderTotal = props.orderData.orderTotal + differenceToCharge;
          newOrderTotal = parseFloat(newOrderTotal.toFixed(2));
        }
        await updateChargedOrder(newOrderTotal, props.orderData.orderID);
        await addAuthNetExtraCharge(differenceToCharge);
        await completeOrderAfterCharge();
      } catch (err) {
        toast.error("Failed to add extra charge.");
      }
    }
    props.setChargeOrder(false);
    props.setShowEditOrderScreen(false);
    await props.getOrderHistory();
    setIsLoading(false);
  }
  async function addAuthNetExtraCharge(chargeAmount) {
    var extraChargeData = {
      amount: chargeAmount,
      profileID: props.orderData.customerID,
      paymentProfileID: props.orderData.paymentProfileID,
    };
    var newData = JSON.stringify(extraChargeData);
    await axios.post(
      `${BASE_API}api/${isDemo}/ChargeCustomerPaymentProfileCapture`,
      newData,
      {
        headers: {
          Authorization: `Bearer ${appContext.userInfo.token}`,
          "Content-Type": "application/json",
          Application: APPLICATION,
        },
      }
    );

    var amountToCharge;
    if (props.orderData.discountedChargePercent === null) {
      amountToCharge = props.orderData.orderTotal;
    } else {
      amountToCharge = props.orderData.discountedTotal;
    }
    var captureData = {
      TransactionAmount: amountToCharge,
      TransactionID: props.orderData.transactionID,
    };

    await axios.post(
      `${BASE_API}api/${isDemo}/capture-authorizenet-payment`,
      captureData,
      {
        headers: {
          Authorization: `Bearer ${appContext.userInfo.token}`,
          "Content-Type": "application/json",
          Application: APPLICATION,
        },
      }
    );
  }
  async function updateChargedOrder(newOrderTotal, orderId) {
    var updateorder = {
      OtherTotal: newOrderTotal,
      OrderID: orderId,
      Note: props.chargeOrderText,
    };
    try {
      await axios.put(`${BASE_API}UpdateEditedOrder`, updateorder, {
        headers: {
          Application: APPLICATION,
        },
      });
    } catch (error) {
      toast.error("Update Order failed");
    }
  }
  async function addMxMerchantExtraCharge(amountToCharge) {
    var totalToCapture;
    if (props.orderData.discountedChargePercent !== null) {
      totalToCapture = props.orderData.discountedTotal;
    } else {
      totalToCapture = props.orderData.orderTotal;
    }
    var mxCaptureData = {
      paymentToken: props.orderData.paymentToken,
      tenderType: "Card",
      amount: totalToCapture,
      authCode: props.orderData.authCode,
      tip: amountToCharge,
    };

    var result = await axios.post(
      `${BASE_API}api/${isDemo}/capture-mxmerchant-payment`,
      mxCaptureData,
      {
        headers: {
          Authorization: `Bearer ${appContext.userInfo.token}`,
          "Content-Type": "application/json",
          Application: APPLICATION,
        },
      }
    );
    result = JSON.parse(result.data.content);
    var id = result.id;
    sendOrderConfirmationText(id, props.orderData.phomeNumber);
  }
  async function sendOrderConfirmationText(id, phone) {
    try {
      var mxData = {
        id: id,
        contact: phone,
      };
      const dataresp = await axios.post(
        `${BASE_API}api/${isDemo}/send-payment-receipt`,
        JSON.stringify(mxData),
        {
          headers: {
            "Content-Type": "application/json",
            Application: APPLICATION,
          },
        }
      );

      return dataresp;
    } catch (err) {
      toast.error(err);
    }
  }
  async function completeOrderAfterCharge() {
    var orderData = props.orderData;
    var utcISOString = getNowTime();

    var completeData = {
      orderId: orderData.orderID,
      completedDate: utcISOString,
    };
    await axios.put(`${BASE_API}CompleteOrder`, completeData, {
      headers: {
        Authorization: `Bearer ${appContext.userInfo.token}`,
        Application: APPLICATION,
      },
    });
  }
  // #endregion
  //

  // #region VOID ITEM(s)
  async function confirmVoidAmount() {
    setIsLoading(true);
    var amountToCharge;
    if (props.orderData.discountedTotal) {
      amountToCharge = props.orderData.discountedTotal - props.voidOrderAmount;
    } else {
      amountToCharge = props.orderData.orderTotal - props.voidOrderAmount;
    }
    amountToCharge = parseFloat(amountToCharge.toFixed(2));
    if (storeContext.mxMerchant === "true") {
      try {
        await captureMxAmountAfterVoid(amountToCharge);
        await updateVoidedOrderData(amountToCharge, props.orderData.orderID);
        await completeOrderAfterVoid();
      } catch (err) {
        toast.error("Failed to void.");
      }
    } else if (storeContext.mxMerchant !== "true") {
      try {
        await captureAuthNetAmountAfterVoid(amountToCharge);
        await updateVoidedOrderData(amountToCharge, props.orderData.orderID);
        await completeOrderAfterVoid();
      } catch (err) {
        toast.error("Failed to void.");
      }
    }
    props.setVoidOrder(false);
    props.setShowEditOrderScreen(false);
    await props.getOrderHistory();
    setIsLoading(false);
  }

  async function captureMxAmountAfterVoid(amountToCharge) {
    var mxCaptureData = {
      paymentToken: props.orderData.paymentToken,
      tenderType: "Card",
      amount: amountToCharge,
      authCode: props.orderData.authCode,
    };

    await axios.post(
      `${BASE_API}api/${isDemo}/capture-mxmerchant-payment`,
      mxCaptureData,
      {
        headers: {
          Authorization: `Bearer ${appContext.userInfo.token}`,
          "Content-Type": "application/json",
          Application: APPLICATION,
        },
      }
    );
  }
  async function captureAuthNetAmountAfterVoid(amountToCharge) {
    var captureData = {
      TransactionAmount: amountToCharge,
      TransactionID: props.orderData.transactionID,
    };

    await axios.post(
      `${BASE_API}api/${isDemo}/capture-authorizenet-payment`,
      captureData,
      {
        headers: {
          Authorization: `Bearer ${appContext.userInfo.token}`,
          "Content-Type": "application/json",
          Application: APPLICATION,
        },
      }
    );
  }
  async function updateVoidedOrderData(otherTotal, orderId) {
    var updateorder = {
      OtherTotal: otherTotal,
      OrderID: orderId,
      Note: "Voided",
    };
    try {
      await axios.put(`${BASE_API}UpdateVoidedOrder`, updateorder, {
        headers: {
          Application: APPLICATION,
        },
      });
    } catch (error) {
      toast.error("Update Voided Order failed.");
    }
  }
  async function completeOrderAfterVoid() {
    var orderData = props.orderData;
    var utcISOString = getNowTime();

    var completeData = {
      orderId: orderData.orderID,
      completedDate: utcISOString,
    };
    await axios.put(`${BASE_API}CompleteOrder`, completeData, {
      headers: {
        Authorization: `Bearer ${appContext.userInfo.token}`,
        Application: APPLICATION,
      },
    });
  }
  // #endregion
  //

  // #region DELETE REWARDS TIER
  async function deleteRewardsTier() {
    setIsLoading(true);
    var tierToDelete = props.currentTier.storeRewardID;

    try {
      await axios.delete(
        `${BASE_API}DeleteRewardsTier?storeRewardID=` +
        tierToDelete,
        {
          headers: {
            Authorization: `Bearer ${appContext.userInfo.token}`,
            Application: APPLICATION,
          },
        }
      );

      props.getRewards();
      setIsLoading(false);
      props.setShowAreYouSure(false);
    } catch (error) {
      toast.error("Error. Please try again.");
      setIsLoading(false);
    }
  }
  // #endregion
  //

  // #region DELETE REWARDS ITEM
  async function deleteRewardItem() {
    setIsLoading(true);

    try {
      await axios.delete(
        `${BASE_API}DeleteRewardItem?storeRewardItemID=` +
        props.itemToDelete.storeRewardItemID,
        {
          headers: {
            Authorization: `Bearer ${appContext.userInfo.token}`,
            Application: APPLICATION,
          },
        }
      );

      props.getRewards();
      setIsLoading(false);
      props.setShowAreYouSure(false);
      props.setShowRewardItemModal(false);
    } catch (error) {
      toast.error("Error. Please try again.");
      setIsLoading(false);
    }
  }
  // #endregion
  //
  return (
    <Modal
      className="areYouSureModal"
      show={props.showAreYouSure}
      backdrop="static"
    >
      <Modal.Body>
        <Modal.Header>
          <FaWindowClose
            onClick={deny}
            style={{ width: "2em", height: "2em" }}
            className="text-danger"
          />
        </Modal.Header>
        {props.type === "cancel" || props.type === "complete" ? (
          <>
            {props.type === "cancel" && <Modal.Title>Are you sure you want to <b style={{ color: "red" }}>cancel</b> this order?</Modal.Title>}
            {props.type === "complete" && <Modal.Title>Are you sure you want to <b style={{ color: "green" }}>complete</b> this order?</Modal.Title>}
          </>
        ) : (
          <Modal.Title>Are you sure you want to make these changes?</Modal.Title>
        )}
        <div className="buttons">
          <button className="no" onClick={deny} disabled={isLoading}>
            {isLoading ? <Loading /> : "No"}
          </button>
          {props.type === "cancel" && (
            <button
              className="yes"
              onClick={confirmCancel}
              disabled={isLoading}
            >
              {isLoading ? <Loading /> : "Yes"}
            </button>
          )}
          {props.type === "complete" && (
            <button
              className="yes"
              onClick={confirmComplete}
              disabled={isLoading}
            >
              {isLoading ? <Loading /> : "Yes"}
            </button>
          )}
          {props.type === "extra_charge" && (
            <button
              className="yes"
              onClick={confirmExtraCharge}
              disabled={isLoading}
            >
              {isLoading ? <Loading /> : "Yes"}
            </button>
          )}
          {props.type === "void_amount" && (
            <button
              className="yes"
              onClick={confirmVoidAmount}
              disabled={isLoading}
            >
              {isLoading ? <Loading /> : "Yes"}
            </button>
          )}
          {props.type === "delete_rewards_tier" && (
            <button
              className="yes"
              onClick={deleteRewardsTier}
              disabled={isLoading}
            >
              {isLoading ? <Loading /> : "Yes"}
            </button>
          )}
          {props.type === "delete_reward_item" && (
            <button
              className="yes"
              onClick={deleteRewardItem}
              disabled={isLoading}
            >
              {isLoading ? <Loading /> : "Yes"}
            </button>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default AreYouSure;
