import React from "react";
import Modal from "react-bootstrap/Modal";
import { FaWindowClose } from "react-icons/fa";
import "./authenticateModal.scss";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Login from "./Login";
import Register from "./Register";
import Guest from "./Guest";

function AuthenticateModal(props) {
  async function closeAuthModal() {
    props.setShowAuthenticateModal(false);
  }
  
  return (
    <>
      <Modal className="authenticateModal" show={props.showAuthenticateModal}>
        <Modal.Body>
          <Modal.Header className="d-flex justify-content-end">
            <FaWindowClose
              onClick={closeAuthModal}
              style={{ width: "2em", height: "2em" }}
              className="text-danger"
            />
          </Modal.Header>
          <Modal.Title>
            {props.authKey === "Login" && (
              <p className="fs-6 pt-2">Please login to continue to checkout</p>
            )}
            {props.authKey === "Register" && (
              <p className="fs-6 pt-2">
                Please register to continue to checkout
              </p>
            )}
            {props.authKey === "Guest" && (
              <p className="fs-6 pt-2">
                Please enter details as a guest to checkout
              </p>
            )}
          </Modal.Title>

          <div className="authenticateModal_body">
            <Tabs
              id="controlled-tab-example"
              activeKey={props.authKey}
              onSelect={(k) => props.setAuthKey(k)}
              className="mb-3 LoginRegister"
            >
              <Tab eventKey="Login" title="Login">
                <Login
                  setShowAuthenticateModal={props.setShowAuthenticateModal}
                  appContext={props.appContext}
                  setAppContext={props.setAppContext}
                />
              </Tab>
              <Tab eventKey="Register" title="Register">
                <Register
                  setAuthKey={props.setAuthKey}
                  appContext={props.appContext}
                  setAppContext={props.setAppContext}
                />
              </Tab>
              <Tab eventKey="Guest" title="Guest">
                <Guest
                  setShowAuthenticateModal={props.setShowAuthenticateModal}
                  setAuthKey={props.setAuthKey}
                  storeContext={props.storeContext}
                  appContext={props.appContext}
                  setAppContext={props.setAppContext}
                />
              </Tab>
            </Tabs>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default AuthenticateModal;
