import React, { useEffect, useContext, useState } from "react";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import "./admin.scss";
import axios from "axios";
import ApplicationContext from "../../ApplicationContext";
import { toast } from "react-toastify";
import { APPLICATION, BASE_API } from "../../constants";
import { formatOrdersData } from "../../utils/orders/FormatUserOrders";
import OpenOrders from "../../components/admin/OpenOrders";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import OrderHistory from "../../components/admin/OrderHistory";
import StoreSettings from "../../components/admin/storeSettings/StoreSettings";
import * as signalR from "@microsoft/signalr";
import NewOrderMessage from "../../components/admin/NewOrderMessage";
import StoreContext from "../../StoreContext";
import Modal from "react-bootstrap/Modal";

function Admin(props) {
  const appContext = useContext(ApplicationContext);
  const storeContext = useContext(StoreContext);

  const [openOrders, setOpenOrders] = useState();
  const [orderHistory, setOrderHistory] = useState();
  const [key, setKey] = useState("Orders");

  const [message, setMessage] = useState("");
  const [showMessage, setShowMessage] = useState();

  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

  const [showOpenMessage, setShowOpenMessage] = useState(true);

  // useEffect(() => {
  //   const connection = new signalR.HubConnectionBuilder()
  //     .withUrl(`${BASE_API}orderHub`)
  //     .build();

  //   connection
  //     .start()
  //     .then(() => console.log("Connection started"))
  //     .catch((err) => console.error("Error while starting connection: ", err));

  //   connection.on(APPLICATION, (receivedMessage) => {
  //     // Your message handling logic
  //     if (key === "Orders") {
  //       getOpenOrders();
  //     } else if (key === "OrderHistory") {
  //       getOrderHistory();
  //     }
  //     if (showMessage !== true) {
  //       setShowMessage(true);
  //     }
  //     console.log(receivedMessage);
  //     setMessage(receivedMessage);
  //   });

  //   // connection.onclose(async (event) => {
  //   //   console.log(event);
  //   //   console.log("Connection closed, attempting to restart...");

  //   //   if (connection.state === signalR.HubConnectionState.Disconnected) {
  //   //     await new Promise((resolve) => setTimeout(resolve, 5000));
  //   //     await connection.start();
  //   //   } else {
  //   //     console.log(
  //   //       "Connection is not in a disconnected state. Not attempting to restart."
  //   //     );
  //   //   }
  //   // });

  //   // return () => {
  //   //   connection.stop();
  //   // };
  // }, [showMessage]);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     window.location.reload();
  //   }, 7200000); // 2 hours

  //   return () => clearInterval(interval);
  // }, []); 


  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    const connection = new signalR.HubConnectionBuilder()
      .withUrl(`${BASE_API}orderHub`)
      .withAutomaticReconnect()
      .configureLogging(signalR.LogLevel.Information)
      .build();

    connection
      .start()
      .then(() => console.log('Connection started'))
      .catch((err) => console.error('Error while starting connection: ', err));

    connection.on(APPLICATION, (receivedMessage) => {
      // Your message handling logic
      if (key === 'Orders') {
        getOpenOrders();
      } else if (key === 'OrderHistory') {
        getOrderHistory();
      }
      if (showMessage !== true) {
        setShowMessage(true);
      }
      console.log(receivedMessage);
      setMessage(receivedMessage);
    });

    connection.onreconnecting((error) => {
      console.assert(connection.state === signalR.HubConnectionState.Reconnecting);
      console.log('Connection lost due to error. Reconnecting.', error);
    });

    connection.onreconnected((connectionId) => {
      console.assert(connection.state === signalR.HubConnectionState.Connected);
      console.log('Connection reestablished. Connected with connectionId', connectionId);
    });

    connection.onclose((error) => {
      console.assert(connection.state === signalR.HubConnectionState.Disconnected);
      console.log('Connection closed due to error. Try refreshing this page to restart the connection', error);
    });

    return () => {
      connection.stop().then(() => console.log('Connection stopped')).catch((err) => console.error('Error while stopping connection: ', err));
    };
  }, [showMessage]);

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    if (key === "Orders") {
      if (APPLICATION) {
        getOpenOrders();
      }
    } else if (key === "Settings") {
      //   getOrderEstimates();
    } else if (key === "OrderHistory") {
      if (APPLICATION) {
        getOrderHistory();
      }
    }
  }, [key, APPLICATION]);

  async function getOpenOrders() {
    try {
      const response = await axios.get(`${BASE_API}GetOpenOrders`, {
        headers: {
          Authorization: `Bearer ${appContext.userInfo.token}`,
          Application: APPLICATION,
        },
      });
      var open = await formatOrdersData(response.data);
      // console.log(open);
      // if(open){
      //   for(var i = 0; i < open.length; i++){
      //     if(storeContext.mxMerchant === "true"){
      //       if(!open[i].paymentToken) {
      //         getOpenOrders();
      //         return;
      //       }
      //     }
      //   }
      // }
      setOpenOrders(open);
    } catch (error) {
      console.log(error);
      toast.error("Could not get open orders.");
    }
  }

  async function getOrderHistory() {
    try {
      const response = await axios.get(
        `${BASE_API}api/AndroidOrders/GetOrders`,
        {
          headers: {
            Authorization: `Bearer ${appContext.userInfo.token}`,
            Application: APPLICATION,
          },
        }
      );
      // var open = await formatOrdersData(response.data);
      setOrderHistory(response.data);
    } catch (error) {
      toast.error("Could not get orders.");
    }
  }

  async function getOrdersBetweenDates(startDate, endDate) {
    try {
      const response = await axios.get(
        `${BASE_API}api/AndroidOrders/GetOrdersBetweenDates`,
        {
          headers: {
            Authorization: `Bearer ${appContext.userInfo.token}`,
            Application: APPLICATION,
          },
          params: {
            startDate: startDate,
            endDate: endDate,
          },
        }
      );
      // var open = await formatOrdersData(response.data);
      setOrderHistory(response.data);
    } catch (error) {
      toast.error("Could not get orders.");
    }
  }

  async function setTheKey(k) {
    setKey(k);

    var alreadyActiveElement = document.querySelector(
      `.AdminTabs .nav-item .nav-link.active`
    );
    if (alreadyActiveElement) {
      alreadyActiveElement.style.backgroundColor = "hsla(0, 0%, 84%, 0.85)";
      alreadyActiveElement.style.color = `black`;
      alreadyActiveElement.style.border = `none`;
      alreadyActiveElement.style.borderBottom = `1px solid black`;
    }

    const alphaValue = 0.5;
    // Parse the original color string to extract the RGB values
    const match = storeContext.color.match(/\d+/g);
    let modifiedLightColor;
    if (match && match.length === 3) {
      const [red, green, blue] = match;

      // Create the new color string with the alpha value
      modifiedLightColor = `rgba(${red}, ${green}, ${blue}, ${alphaValue})`;
    }

    var activeThisElement = document.getElementById(
      `controlled-tab-example-tab-${k}`
    );
    if (activeThisElement) {
      activeThisElement.style.backgroundColor = modifiedLightColor;
      activeThisElement.style.color = `white`;
      activeThisElement.style.border = `3px solid ${storeContext.color}`;
      activeThisElement.style.borderBottom = `3px solid ${storeContext.color}`;
    }
  }

  useEffect(() => {
    var activeThisElement = document.getElementById(
      `controlled-tab-example-tab-${key}`
    );
    const alphaValue = 0.5;
    // Parse the original color string to extract the RGB values
    const match = storeContext.color.match(/\d+/g);
    let modifiedLightColor;
    if (match && match.length === 3) {
      const [red, green, blue] = match;

      // Create the new color string with the alpha value
      modifiedLightColor = `rgba(${red}, ${green}, ${blue}, ${alphaValue})`;
    }
    if (activeThisElement) {
      activeThisElement.style.backgroundColor = modifiedLightColor;
      activeThisElement.style.color = `white`;
      activeThisElement.style.border = `2px solid ${storeContext.color}`;
      activeThisElement.style.borderBottom = `2px solid ${storeContext.color}`;
    }
  }, []);

  useEffect(() => {
    simulateUserGesture();
  }, []);

  // Simulate a user gesture by dispatching a click event
  const simulateUserGesture = () => {
    console.log("simulating");
    const event = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    document.dispatchEvent(event);
  };

  async function closeOpenMessage() {
    setShowOpenMessage(false);
  }

  return (
    <>
      <Navbar
        setAppContext={props.setAppContext}
        setAuthKey={props.setAuthKey}
        setShowAuthenticateModal={props.setShowAuthenticateModal}
      />
      <div className="admin">
        {showOpenMessage && (
          <Modal
            className="openAdminPortalMessage"
            onClick={closeOpenMessage}
            show={showOpenMessage}
          >
            <Modal.Body>
              <div className="body">
                <div className="admin_welcome">
                  Welcome to {storeContext.name} Admin Portal!
                </div>
                <div className="admin_message">
                  Click on this message to <b>TURN ON SOUND</b> for New Orders!
                </div>
              </div>
            </Modal.Body>
          </Modal>
        )}
        {showMessage && (
          <>
            {message && (
              <NewOrderMessage
                message={message}
                setShowMessage={setShowMessage}
              />
            )}
          </>
        )}
        <div className="fs-2 fw-bold mt-2">Admin Portal</div>
        <div className="adminTabsContainer">
          <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => setTheKey(k)}
            className="mb-3 AdminTabs"
          >
            <Tab eventKey="Orders" title="Open Orders">
              <OpenOrders
                getOpenOrders={getOpenOrders}
                openOrders={openOrders}
              />
            </Tab>
            <Tab eventKey="OrderHistory" title="Order History">
              <OrderHistory
                getOrdersBetweenDates={getOrdersBetweenDates}
                orderHistory={orderHistory}
                setOrderHistory={setOrderHistory}
                getOrderHistory={getOrderHistory}
                selectedMonth={selectedMonth}
                setSelectedMonth={setSelectedMonth}
                selectedYear={selectedYear}
                setSelectedYear={setSelectedYear}
                key={key}
              />
            </Tab>
            <Tab eventKey="Settings" title="Store Settings">
              <StoreSettings setStoreContext={props.setStoreContext} menuContext={props.menuContext} />
            </Tab>
          </Tabs>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Admin;
