export const getNowTime = () => {
    const now = new Date();
    const isDST = () => {
      const year = now.getFullYear();
      const dstStart = new Date(year, 2, 8); // DST starts on second Sunday in March
      const dstEnd = new Date(year, 10, 1); // DST ends on first Sunday in November
      dstStart.setDate(8 - dstStart.getDay());
      dstEnd.setDate(1 - dstEnd.getDay() + 7 * 2);
      return now >= dstStart && now < dstEnd;
    };
  
    const estOffset = isDST() ? -4 * 60 : -5 * 60; // Adjust offset for DST
    const utcDate = new Date(now.getTime() + estOffset * 60 * 1000);
    
    return utcDate.toISOString();
}