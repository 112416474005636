const stores = [
    {
        name: "Frank's Pizza",
        image: "https://i.ibb.co/gRTXYt6/pizza.jpg",
        address: "5762 Berkshire Valley Rd, Oak Ridge, NJ 07438",
        latitude: "41.037475",
        longitude: "-74.504721",
        orderLink: "https://myorderportal.com/frankspizzaoakridge"
    },
    {
        name: "Somerset Pizza",
        image: "https://i.ibb.co/wYmyWPL/pizzas.jpg",
        address: "147 Pierce St, Somerset, NJ 08873",
        latitude: "40.5357408",
        longitude: "-74.5393893",
        orderLink: "https://myorderportal.com/somersetpizza"
    },
    {
        name: "Brenda Lee Restaurant",
        image: "https://i.ibb.co/KDXW9rx/tacos.jpg",
        address: "15 E Blackwell St, Dover, NJ 07801",
        latitude: "40.884348",
        longitude: "-74.557658",
        orderLink: "https://myorderportal.com/brendaleedover"
    },
    {
        name: "Little Italy Brick Oven",
        image: "https://i.ibb.co/wLmZrVv/chick-parm.jpg",
        address: "459 NJ-31, Washington Twp, NJ 07882",
        latitude: "40.7207385",
        longitude: "-74.9712099",
        orderLink: "https://myorderportal.com/littleitalybrickoven"
    },
    {
        name: "Annabel's Pizza & Italian Restaurant",
        image: "https://i.ibb.co/ns2vyH1/wings.jpg",
        address: "6 Main St, Unionville, NY 10988",
        latitude: "41.3020822",
        longitude: "-74.5622522",
        orderLink: "https://myorderportal.com/annabelspizzaunionville"
    },
    {
        name: "Pezzo Pizza Uno",
        image: "https://i.ibb.co/8D1NXqZ/pezzo-pizzas.jpg",
        address: "227 Newton Sparta Rd #1, Newton, NJ 07860",
        latitude: "41.0382397",
        longitude: "-74.7036149",
        orderLink: "https://myorderportal.com/pezzopizzauno"
    },
    {
        name: "Pizza Rustica",
        image: "https://i.ibb.co/p21gXLF/pizza1.jpg",
        address: "25 W Main St, Rockaway, NJ 07866",
        latitude: "40.9009941",
        longitude: "-74.5141316",
        orderLink: "https://myorderportal.com/pizzarusticarockaway"
    },
    {
        name: "La Casa Del Taco",
        image: "https://i.ibb.co/DVsN8WQ/tacos.jpg",
        address: "1965 Rt 57, Allen Rd, Hackettstown, NJ 07840",
        latitude: "40.8236764",
        longitude: "-74.8360437",
        orderLink: "https://myorderportal.com/lacasadeltaco"
    },
    {
        name: "Frank's Pizza",
        image: "https://i.ibb.co/nQRWpMC/penne-vodka.jpg",
        address: "152 NJ-94, Blairstown, NJ 07825",
        latitude: "40.98309",
        longitude: "-74.9951008",
        orderLink: "https://myorderportal.com/frankspizzablairstown"
    },
    {
        name: "Rivoli Pizzeria",
        image: "https://i.ibb.co/0C8NL7C/platter.jpg",
        address: "2193 Morris Ave, Union, NJ 07083",
        latitude: "40.69851",
        longitude: "-74.27876",
        orderLink: "https://myorderportal.com/rivolipizzeriaunion"
    },
    {
        name: "Vincenzo's Pizza",
        image: "https://i.ibb.co/ckH6PFF/Chicken-Francese.jpg",
        address: "3 Netcong Rd, Budd Lake, NJ 07828",
        latitude: "40.8835126",
        longitude: "-74.7310823",
        orderLink: "https://myorderportal.com/vincenzopizzabuddlake"
    },
];

export default stores;