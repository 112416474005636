import React, { useState, useContext, useEffect } from "react";
import "./rewards.scss";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import { APPLICATION, BASE_API } from "../../constants";
import StoreContext from "../../StoreContext";
import axios from "axios";
import RewardItemModal from "../../components/itemModal/RewardItemModal";
import ApplicationContext from "../../ApplicationContext";
import { AiFillLock, AiFillUnlock } from "react-icons/ai";
import Cart from "../../components/cart/Cart";
import CartItems from "../../components/cart/CartItems";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { BsArrowRightSquareFill } from "react-icons/bs";

function Rewards(props) {
  const navigate = useNavigate();
  const storeContext = useContext(StoreContext);
  const appContext = useContext(ApplicationContext);

  const [rewardOptions, setRewardOptions] = useState([]);
  const [newItemPrice, setNewItemPrice] = useState();
  const [pointsForThisReward, setPointsForThisReward] = useState();

  useEffect(() => {
    GetRewards();
  }, []);

  async function GetRewards() {
    try {
      const response = await axios.get(`${BASE_API}GetRewards`, {
        headers: {
          Application: APPLICATION,
        },
      });

      for (var i = 0; i < response.data.rewards.length; i++) {
        response.data.rewards[i].items = [];
        for (var j = 0; j < response.data.rewardItems.length; j++) {
          if (
            response.data.rewards[i].storeRewardID ===
            response.data.rewardItems[j].rewardItem_StoreRewardID
          ) {
            response.data.rewards[i].items.push(response.data.rewardItems[j]);
          }
        }
      }

      for (var v = 0; v < response.data.rewards.length; v++) {
        response.data.rewards.sort((a, b) => a.points - b.points);
      }

      for (var l = 0; l < response.data.rewards.length; l++) {
        for (var k = 0; k < response.data.rewards[l].items.length; k++) {
          response.data.rewards[l].items.sort(
            (a, b) => a.rewardItem_MenuItemID - b.rewardItem_MenuItemID
          );
        }
      }
      setRewardOptions(response.data.rewards);
    } catch (error) {
      console.error("Error fetching rewards:", error);
    }
  }

  async function toggleItemModal(item, points) {
    setNewItemPrice(item.rewardItemPrice);
    setPointsForThisReward(points);

    item.active = "Active";
    props.setSelectedItem(item);
    props.setShowItemModal(true);
  }

  function checkout() {
    if (storeContext.mxMerchant === "true") {
      if (
        !appContext.userInfo.role ||
        !appContext.userInfo.firstName ||
        appContext.userInfo.role === "Guest"
      ) {
        props.setAuthKey("Guest");
        props.setShowAuthenticateModal(true);
      } else if (appContext.cart.CartPrice >= storeContext.orderMin) {
        navigate("order");
      } else {
        toast.error(`Mimimum order amount is $${storeContext.orderMin}.`);
      }
    } else {
      if (
        !appContext.userInfo.role ||
        !appContext.userInfo.email ||
        !appContext.userInfo.firstName ||
        appContext.userInfo.role === "Guest"
      ) {
        props.setAuthKey("Guest");
        props.setShowAuthenticateModal(true);
      } else if (appContext.cart.CartPrice >= storeContext.orderMin) {
        navigate("order");
      } else {
        toast.error(`Mimimum order amount is $${storeContext.orderMin}.`);
      }
    }
  }

  return (
    <>
      {props.showItemModal ? (
        <RewardItemModal
          pointsForThisReward={pointsForThisReward}
          newPrice={newItemPrice}
          showItemModal={props.showItemModal}
          setShowItemModal={props.setShowItemModal}
          selectedItem={props.selectedItem}
          setAppContext={props.setAppContext}
        />
      ) : null}

      <Navbar
        setAuthKey={props.setAuthKey}
        setShowAuthenticateModal={props.setShowAuthenticateModal}
        setAppContext={props.setAppContext}
      />

      {appContext.cartItems && (
        <>
          {appContext.cartItems.length >= 1 && (
            <Cart
              detailsAreLoading={props.detailsAreLoading}
              cartOpen={props.cartOpen}
              setCartOpen={props.setCartOpen}
              setShowAuthenticateModal={props.setShowAuthenticateModal}
              showEditItemModal={props.showEditItemModal}
              setShowEditItemModal={props.setShowEditItemModal}
              selectedItem={props.selectedItem}
              setSelectedItem={props.setSelectedItem}
              setAppContext={props.setAppContext}
              setAuthKey={props.setAuthKey}
            />
          )}
        </>
      )}

      <div className="rewards_page">
        <div className="main_container">
          <div className="title">Loyalty Rewards</div>

          {appContext.userInfo && (
            <div className="points">
              My Rewards:
              <span className="my_points mx-1">
                {appContext.userInfo.rewardPoints} Points
              </span>
            </div>
          )}

          <h2>{props.showItemModal}</h2>

          {rewardOptions?.slice().map((rewards) => (
            <>
              {rewards.items.length > 0 && (
                <div
                  className="reward_options_container"
                  key={rewards.storeRewardID}
                >
                  {appContext.userInfo ? (
                    <>
                      {appContext.userInfo.rewardPoints >= rewards.points ? (
                        <>
                          <div className="points">
                            Redeem for {rewards.points} points
                            <span>
                              <AiFillUnlock className="unlock" />
                            </span>
                          </div>
                          {rewards.items?.map((item) => (
                            <>
                              <div
                                className="reward_items_container card"
                                key={item.rewardItem_MenuItemID}
                                onClick={() =>
                                  toggleItemModal(item, rewards.points)
                                }
                              >
                                {item.rewardItem_MenuItemID === -1 ? (
                                  <>
                                    <h4 className="fw-bold itemName my-2">
                                      Coupon - $
                                      {item.rewardItemPrice.toFixed(2)} Discount
                                    </h4>
                                  </>
                                ) : (
                                  <>
                                    <h4 className="fw-bold itemName">
                                      {item.itemSize ? (
                                        <>
                                          {item.itemName +
                                            " (" +
                                            item.itemSize +
                                            ")"}
                                        </>
                                      ) : (
                                        <>{item.itemName}</>
                                      )}
                                    </h4>
                                    {item.itemDescription && (
                                      <p className="mb-1 itemDescription">
                                        <em>{item.itemDescription}</em>
                                      </p>
                                    )}
                                    <p className="mb-0 itemPrice">
                                      <s>{"$" + item.itemPrice.toFixed(2)}</s>
                                      <span className="mx-1 fw-bold">
                                        ${item.rewardItemPrice.toFixed(2)}
                                      </span>
                                    </p>
                                  </>
                                )}
                              </div>
                            </>
                          ))}
                        </>
                      ) : (
                        <>
                          <div className="points">
                            Redeem for {rewards.points} points
                            <span>
                              <AiFillLock className="lock" />
                            </span>
                          </div>
                          {rewards.items?.map((item) => (
                            <>
                              <div
                                className="reward_items_container card disabled"
                                key={item.rewardItem_MenuItemID}
                              >
                                {item.rewardItem_MenuItemID === -1 ? (
                                  <>
                                    <h4 className="fw-bold itemName my-2">
                                      Coupon - $
                                      {item.rewardItemPrice.toFixed(2)} Discount
                                    </h4>
                                  </>
                                ) : (
                                  <>
                                    <h4 className="fw-bold itemName">
                                      {item.itemName}
                                    </h4>
                                    {item.itemDescription && (
                                      <p className="mb-1 itemDescription">
                                        <em>{item.itemDescription}</em>
                                      </p>
                                    )}
                                    <p className="mb-0 itemPrice">
                                      <s>{"$" + item.itemPrice.toFixed(2)}</s>
                                      <span className="mx-1 fw-bold">
                                        ${item.rewardItemPrice.toFixed(2)}
                                      </span>
                                    </p>
                                  </>
                                )}
                              </div>
                            </>
                          ))}
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <AiFillLock />
                      <h4 className="points">
                        Redeem for {rewards.points} points
                      </h4>

                      {rewards.items?.map((item) => (
                        <>
                          <div
                            className="reward_items_container card disabled"
                            key={item.rewardItem_MenuItemID}
                          >
                            {item.rewardItem_MenuItemID === -1 ? (
                              <>
                                <h4 className="fw-bold itemName my-2">
                                  Coupon - ${item.rewardItemPrice.toFixed(2)}{" "}
                                  Discount
                                </h4>
                              </>
                            ) : (
                              <>
                                <h4 className="fw-bold itemName">
                                  {item.itemName}
                                </h4>
                                {item.itemDescription && (
                                  <p className="mb-1 itemDescription">
                                    <em>{item.itemDescription}</em>
                                  </p>
                                )}
                                <p className="mb-0 itemPrice">
                                  <s>{"$" + item.itemPrice.toFixed(2)}</s>
                                  <span className="mx-1 fw-bold">
                                    ${item.rewardItemPrice.toFixed(2)}
                                  </span>
                                </p>
                              </>
                            )}
                          </div>
                        </>
                      ))}
                    </>
                  )}
                </div>
              )}
            </>
          ))}
        </div>

        <div className="desktop_cart_container">
          <div className="desktop_cart">
            <div className="title">My Cart</div>
            <div className="cart_body">
              {appContext.cartItems ? (
                <>
                  {appContext.cartItems.length > 0 ? (
                    <>
                      <CartItems
                        isDisabled={props.isDisabled}
                        setIsDisabled={props.setIsDisabled}
                        setSelectedItem={props.setSelectedItem}
                        setShowEditItemModal={props.setShowEditItemModal}
                        setCartOpen={props.setCartOpen}
                        setAppContext={props.setAppContext}
                      />
                      <div
                        className="checkout_container"
                        id="checkout_container"
                        onClick={checkout}
                      >
                        <p className="total">
                          Total: ${appContext.cart.CartPrice.toFixed(2)}
                        </p>
                        <div className="checkout_text">
                          <p className="text">Checkout</p>
                          <BsArrowRightSquareFill />
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="empty">
                      <img src="/pictures/emptycart.png" alt="" />
                    </div>
                  )}
                </>
              ) : (
                <div className="empty">
                  <img src="/pictures/emptycart.png" alt="" />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Rewards;
