import React, { useState, useEffect, useContext } from "react";
import AreYouSure from "./areYouSure/AreYouSure";
import StoreContext from "../../StoreContext";

function OpenOrders(props) {
  const [showAreYouSureCancel, setShowAreYouSureCancel] = useState(false);
  const [showAreYouSureComplete, setShowAreYouSureComplete] = useState(false);
  const [orderData, setOrderData] = useState({});
  const storeContext = useContext(StoreContext);

  async function toggleAreYouSureComplete(order) {
    setOrderData(order);
    setShowAreYouSureComplete(true);
  }
  async function toggleAreYouSureCancel(order) {
    setOrderData(order);
    setShowAreYouSureCancel(true);
  }

  useEffect(() => {
    if (storeContext.color) {
      const element = document.getElementById("open_orders");
      if (element) {
        element.style.backgroundColor = storeContext.color;
      }
    }
  }, []);

  return (
    <>
      {showAreYouSureCancel && (
        <AreYouSure
          getOpenOrders={props.getOpenOrders}
          orderData={orderData}
          showAreYouSure={showAreYouSureCancel}
          setShowAreYouSure={setShowAreYouSureCancel}
          type={"cancel"}
        />
      )}
      {showAreYouSureComplete && (
        <AreYouSure
          getOpenOrders={props.getOpenOrders}
          orderData={orderData}
          showAreYouSure={showAreYouSureComplete}
          setShowAreYouSure={setShowAreYouSureComplete}
          type={"complete"}
        />
      )}
      <div className="orders_container">
        {props.openOrders ? (
          <>
            {props.openOrders?.map((i) => (
              <div className="card order" key={i.orderID}>
                <div className="card-header order_header d-flex justify-content-start w-100 align-items-center">
                  <div className="orderNumber fw-bold fs-3">
                    {"#" + i.orderNumber}
                  </div>
                  <div className="orderType fw-bold fs-3">{i.orderType}</div>
                  <div className="orderDetails fs-4 fw-bold">
                    {i.name + " - " + i.requestedDate}
                  </div>
                </div>
                <div id={`body_${i.orderID}`} className="card-body">
                  {i.payLater === "true" && (
                    <div className="payLater">Collect Payment</div>
                  )}
                  {i.orderType === "Delivery" && (
                    <div className="delivery_address card d-flex align-items-center justify-content-center">
                      <div className="address_label fs-5 fw-bold">Address:</div>
                      <div>
                        {i.streetAddress + " " + i.city + " " + i.stateName}
                      </div>
                    </div>
                  )}
                  <div className={`order_items order_${i.orderID}`}>
                    {i.items?.map((item) => (
                      <div
                        key={item.orderItemID}
                        className="item d-flex w-100 justify-content-between"
                      >
                        <div className="left">
                          <div className="item_name">
                            {item.sizeCode ? (
                              <h5 className="content">
                                {item.itemQuantity +
                                  "x- " +
                                  item.itemName +
                                  " - " +
                                  item.sizeCode}
                              </h5>
                            ) : (
                              <h5 className="content">
                                {item.itemName === null ? (
                                  <>
                                    {item.itemQuantity +
                                      "x - $" +
                                      Math.abs(item.itemTotalPrice) +
                                      " Rewards Discount"}
                                  </>
                                ) : (
                                  <>
                                    {item.itemQuantity +
                                      "x - " +
                                      item.itemName}
                                  </>
                                )}
                              </h5>
                            )}
                          </div>
                          {item.extras?.map((extra) => (
                            <div
                              key={extra.orderItemExtraID}
                              className="item_extra"
                            >
                              {"-" + extra.extraName}
                              {extra.pizzaHalfCode && " " + extra.pizzaHalfCode}
                            </div>
                          ))}
                          {item.itemSpecialRequests && (
                            <div className="item_extra specialRequest">
                              {'"' + item.itemSpecialRequests + '"'}
                            </div>
                          )}
                        </div>
                        <div className="right">
                          <h5 className="content">
                            {"$" + item.itemTotalPrice}
                          </h5>
                        </div>
                      </div>
                    ))}

                    <div className="order_totals">
                      <div className="left">
                        <h5 className="content">Subtotal</h5>
                        <h5 className="content">Tax</h5>
                        <h5 className="content">Tip</h5>
                        <h5 className="content">Final Total</h5>
                      </div>
                      <div className="right">
                        <>
                          {i.discountedSubtotal ? (
                            <h5 className="content price">
                              <s className="oldPrice">
                                ${i.orderSubtotal.toFixed(2)}
                              </s>
                              <div>${i.discountedSubtotal.toFixed(2)}</div>
                            </h5>
                          ) : (
                            <h5 className="content price">
                              ${i.orderSubtotal.toFixed(2)}
                            </h5>
                          )}
                        </>
                        <>
                          {i.discountedTax ? (
                            <h5 className="content price">
                              <s className="oldPrice">
                                ${i.orderTax.toFixed(2)}
                              </s>
                              <div>${i.discountedTax.toFixed(2)}</div>
                            </h5>
                          ) : (
                            <h5 className="content price">
                              ${i.orderTax.toFixed(2)}
                            </h5>
                          )}
                        </>
                        <h5 className="content price">
                          ${i.orderTip.toFixed(2)}
                        </h5>
                        <>
                          {i.discountedTotal ? (
                            <h5 className="content price">
                              <s className="oldPrice">
                                ${i.orderTotal.toFixed(2)}
                              </s>
                              <div>${i.discountedTotal.toFixed(2)}</div>
                            </h5>
                          ) : (
                            <h5 className="content price">
                              ${i.orderTotal.toFixed(2)}
                            </h5>
                          )}
                        </>
                      </div>
                    </div>
                  </div>
                  <div className="order_footer">
                    {i.payLater === "true" ? (
                      <button
                        className="complete"
                        onClick={() => toggleAreYouSureComplete(i)}
                      >
                        Payment Collected
                      </button>
                    ) : (
                      <>
                        <button
                          className="cancel"
                          onClick={() => toggleAreYouSureCancel(i)}
                        >
                          Cancel Order
                        </button>
                        <button
                          className="complete"
                          onClick={() => toggleAreYouSureComplete(i)}
                        >
                          Complete Order
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </>
        ) : (
          <div className="mt-5 fs-5 fw-bold d-flex justify-content-center align-center">
            No Open Orders Today
          </div>
        )}
      </div>
    </>
  );
}

export default OpenOrders;
