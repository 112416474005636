import React, { useContext, useState, useEffect } from "react";
import ApplicationContext from "../../../ApplicationContext";
import StoreContext from "../../../StoreContext";
import { toast } from "react-toastify";
import axios from "axios";
import { APPLICATION, BASE_API, STORE_NAME } from "../../../constants";
import AreYouSure from "../areYouSure/AreYouSure";
import RewardTierItemModal from "../../rewardsModals/RewardTierItemModal";
import AddRewardTierModal from "../../rewardsModals/AddRewardTierModal";

function StoreRewards(props) {
  const appContext = useContext(ApplicationContext);
  const storeContext = useContext(StoreContext);

  const [isActive, setIsActive] = useState(); // State to track toggle status

  const [allRewards, setAllRewards] = useState();
  const [showAreYouSureDeleteRewardsTier, setShowAreYouSureDeleteRewardsTier] = useState(false);
  const [currentTier, setCurrentTier] = useState();
  const [showRewardItemModal, setShowRewardItemModal] = useState(false);
  const [showRewardTierModal, setShowRewardTierModal] = useState(false);
  const [rewardItems, setRewardItems] = useState({});

  useEffect(() => {
    if (storeContext.rewardsProgram === "true") {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  }, []);

  useEffect(() => {
    if (!allRewards) {
      GetRewards();
    }
  }, []);

  async function GetRewards() {
    try {
      const response = await axios.get(`${BASE_API}GetRewards`, {
        headers: {
          Application: APPLICATION,
        },
      });

      for (var i = 0; i < response.data.rewards.length; i++) {
        response.data.rewards[i].items = [];
        for (var j = 0; j < response.data.rewardItems.length; j++) {
          if (
            response.data.rewards[i].storeRewardID ===
            response.data.rewardItems[j].rewardItem_StoreRewardID
          ) {
            response.data.rewards[i].items.push(response.data.rewardItems[j]);
          }
        }
      }

      for (var v = 0; v < response.data.rewards.length; v++) {
        response.data.rewards.sort(
          (a, b) => a.points - b.points
        );
      }

      for (var l = 0; l < response.data.rewards.length; l++) {
        for (var k = 0; k < response.data.rewards[l].items.length; k++) {
          response.data.rewards[l].items.sort(
            (a, b) => a.rewardItem_MenuItemID - b.rewardItem_MenuItemID
          );
        }
      }

      setAllRewards(response.data.rewards);
    } catch (error) {
      console.error("Error fetching rewards:", error);
    }
  }

  async function handleToggle() {
    var rewardsProgram = {
      status: "",
    };
    try {
      if (isActive === true) {
        rewardsProgram.status = "false";
      } else {
        rewardsProgram.status = "true";
      }

      await axios.put(
        `${BASE_API}UpdateStoreRewardsStatus/${rewardsProgram.status}`,
        rewardsProgram.status,
        {
          headers: {
            Authorization: `Bearer ${appContext.userInfo.token}`,
            Application: APPLICATION,
          },
        }
      );
      var newStoreContext = storeContext;
      newStoreContext.rewardsProgram = rewardsProgram.status;
      props.setStoreContext(newStoreContext);
      localStorage.setItem("storeContext", JSON.stringify(newStoreContext));
    } catch (error) {
      toast.error("Error. Please try again.");
    }

    setIsActive(!isActive); // Toggle the active state
  }

  async function deleteRewardTier(tier) {
    setCurrentTier(tier);
    setShowAreYouSureDeleteRewardsTier(true);
  }

  async function editRewardTier(tier) {
    for (var a = 0; a < allRewards.length; a++) {
      if (allRewards[a].points === tier.points) {
        setRewardItems(allRewards[a]);
      }
    }

    setCurrentTier(tier);
    setShowRewardItemModal(true);
  }

  async function addRewardTier() {
    setShowRewardTierModal(true);
  }

  return (
    <>
      {showAreYouSureDeleteRewardsTier && (
        <AreYouSure
          showAreYouSure={showAreYouSureDeleteRewardsTier}
          setShowAreYouSure={setShowAreYouSureDeleteRewardsTier}
          currentTier={currentTier}
          getRewards={GetRewards}
          type={"delete_rewards_tier"}
        />
      )}

      {showRewardItemModal && (
        <RewardTierItemModal
          showRewardItemModal={showRewardItemModal}
          setShowRewardItemModal={setShowRewardItemModal}
          currentTier={currentTier}
          rewardItems={rewardItems}
          getRewards={GetRewards}
          allRewards={allRewards}
          menuContext={props.menuContext}
        />
      )}

      {showRewardTierModal && (
        <AddRewardTierModal
          showRewardTierModal={showRewardTierModal}
          setShowRewardTierModal={setShowRewardTierModal}
          getRewards={GetRewards}
        />
      )}

      <div className="store_rewards card">
        <div className="card-body">
          <div className="rewards_header">
            <div className="rewards_toggle">
              <h3 className="title">Rewards Program</h3>
              <div
                className={`toggle-button ${isActive ? "" : "active"}`}
                onClick={handleToggle}
              >
                <div className="toggle-option on">On</div>
                <div className="toggle-option off">Off</div>
              </div>
            </div>
            {isActive && <div className="add_tier" onClick={() => addRewardTier()}>Add New Reward Tier</div>}
          </div>

          <div className="rewards_notice">$1 spent by the Customer = 1 Reward Point</div>

          {isActive && (
            <div className="reward_tiers_container">
              {allRewards?.map((reward, index) => (
                <div
                  key={reward.rewardName}
                  className="reward_tier"
                >
                  <div className="reward_tier_header">
                    <div className="reward_tier_detail">
                      <div className="reward_tier_points">{reward.points} Points</div>
                    </div>
                    <div className="reward_buttons">
                      <div className="edit_reward_tier button" onClick={() => editRewardTier(reward)}>Edit Reward Tier</div>
                      <div className="delete_reward_tier button" onClick={() => deleteRewardTier(reward)}>Delete</div>
                    </div>
                  </div>
                  <div className="items_text">Items:</div>
                  <div className="reward_items_container">
                    {reward.items?.map((item) => (
                      <div
                        key={item.rewardItem_MenuItemID}
                        className="reward_item"
                      >
                        {item.rewardItem_MenuItemID < 0 ? (
                          <>
                            <div className="item">
                              ${item.rewardItemPrice} Discount
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="item">
                              {item.itemSize ? (
                                <>
                                  {item.itemName + " (" + item.itemSize + ") - $" + item.rewardItemPrice}
                                </>
                              ) : (
                                <>
                                  {item.itemName + " - $" + item.rewardItemPrice}

                                </>
                              )}
                            </div>
                          </>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </>

  );
}

export default StoreRewards;
