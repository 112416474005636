import React, { useEffect } from "react";
import useSound from "use-sound";
import soundFile from "../../sounds/sound.wav";

function NewOrderMessage(props) {
  const [play, { stop }] = useSound(soundFile);

  useEffect(() => {
    const initAudioContext = async () => {
      try {
        // create an AudioContext on component mount
        await new AudioContext().resume();
        play(); // play the sound after the AudioContext has been resumed
      } catch (error) {
        console.error("Error initializing AudioContext:", error);
      }
    };

    initAudioContext();

    return () => {
      stop();
    };
  }, [play, stop]);

  async function closeMessage() {
    props.setShowMessage(false);
    stop();
  }

  return (
    <div className="newOrderMessage" onClick={closeMessage}>
      <h1 className="fs-1 mb-3 fw-bold">You have a New Order!</h1>
      <div className="fs-6">
        Please click anywhere on this message to acknowledge.
      </div>
    </div>
  );
}

export default NewOrderMessage;
